import axios from "axios";

export const getAllTenants = (token) => {
  return axios.get(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}tenants`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createTenant = (tenantName, status, token) => {
  return axios.post(`${process.env.REACT_APP_OPERATOR_API_GATEWAY_URL}tenants`, { tenantName, status },
    {
      headers:
        { Authorization: `Bearer ${token}` },
    }
  );
};

