import axios from "axios";

export const getPaginatedFuelStationDetails = async (token, exclusiveStartKey = null, limit = 10) => {
  try {
    const params = { limit };
    if (exclusiveStartKey) {
      params.exclusiveStartKey = JSON.stringify(exclusiveStartKey);
    }

    const response = await axios.get(
      `${process.env.REACT_APP_MY_RIDE_API}/get-all-fuel-stations`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching fuel station details:", error);
    throw error;
  }
};


export const updateFuelStationStatus = async (fuelStationID, newStatus, token) => {
  try {
    const apiUrl = `${process.env.REACT_APP_MY_RIDE_API}/fuel-station/${fuelStationID}/status`;
    const payload = { status: newStatus };

    const response = await axios.put(apiUrl, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating fuel station status:", error);
    throw error;
  }
};


export const getFuelStations = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_MY_RIDE_API}/get-fuel-stations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data; 
  } catch (error) {
    console.error("Error fetching fuel stations:", error);
    return { success: false, message: "Failed to fetch fuel stations." };
  }
};

