import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import { Spinner } from "../utils/Spinner";
import { showDefaultAlert } from "../utils/alerts";
import { getFuelStations, updateFuelStationStatus } from "../services/FuelStationServices";
import { UserContext } from "../contexts/UserContext";

const FuelStationDetails = () => {
  const { token } = useContext(UserContext);

  // Styled Table Cells
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // State for data, search, sorting, and pagination
  const [fuelStationData, setFuelStationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10; // Fixed number of rows per page
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch Fuel Stations
  const fetchFuelStationDetails = async () => {
    setLoading(true);
    try {
      const response = await getFuelStations(token);
      const { data } = response;

      if (!data || !Array.isArray(data)) {
        throw new Error("Invalid data format received from API.");
      }

      const transformedData = data
        .map((item) => {
          const flattenedItem = {};
          for (const key in item) {
            if (item[key]?.S) {
              flattenedItem[key] = item[key].S;
            } else if (item[key]?.N) {
              flattenedItem[key] = Number(item[key].N);
            } else if (item[key]?.BOOL) {
              flattenedItem[key] = item[key].BOOL;
            } else {
              flattenedItem[key] = "";
            }
          }
          return flattenedItem;
        })
        .filter((station) => station.UpdatedAt && !isNaN(station.UpdatedAt)); // Remove invalid timestamps

        const sortedData = transformedData.sort((a, b) => b.UpdatedAt - a.UpdatedAt); // Sort by latest updated first
        setFuelStationData(sortedData);
        setFilteredData(sortedData);
        
    } catch (error) {
      showDefaultAlert("error", "Error", "Failed to fetch fuel station details.");
    } finally {
      setLoading(false);
    }
  };

  // Handle Status Change
  const handleStatusChange = async (fuelStationID, newStatus) => {
    try {
      await updateFuelStationStatus(fuelStationID, newStatus);
      setFuelStationData((prevData) =>
        prevData.map((station) =>
          station.FuelStationID === fuelStationID ? { ...station, Status: newStatus } : station
        )
      );
      showDefaultAlert("success", "Success", "Fuel station status updated successfully.");
    } catch (error) {
      showDefaultAlert("error", "Error", "Failed to update status.");
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchFuelStationDetails();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const sortDataByUpdatedAt = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (!a.UpdatedAt) return 1; // Move invalid timestamps to the bottom
      if (!b.UpdatedAt) return -1;
      return sortOrder === "asc" ? a.UpdatedAt - b.UpdatedAt : b.UpdatedAt - a.UpdatedAt;
    });

    setFilteredData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Handle Search Functionality
  useEffect(() => {
    if (!searchQuery) {
      setFilteredData(fuelStationData);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filteredResults = fuelStationData.filter(
        (station) =>
          station.FuelStationName.toLowerCase().includes(lowerCaseQuery) ||
          station.City.toLowerCase().includes(lowerCaseQuery) ||
          station.Region.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredData(filteredResults);
    }
    setPage(1); // Reset pagination when searching
  }, [searchQuery, fuelStationData]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <div className="container py-5">
      <header className="text-center">
        <h1>Fuel Stations</h1>
      </header>

      {loading && fuelStationData.length === 0 ? (
        <Spinner />
      ) : (
        <>
          {/* Search Bar */}
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <TextField
              label="Search Fuel Station"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button variant="contained" onClick={sortDataByUpdatedAt} style={{ marginLeft: 10 }}>
              Sort by UpdatedAt ({sortOrder === "asc" ? "Oldest First" : "Newest First"})
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="fuel station table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Station Name</StyledTableCell>
                  <StyledTableCell align="right">Address</StyledTableCell>
                  <StyledTableCell align="right">City</StyledTableCell>
                  <StyledTableCell align="right">Region</StyledTableCell>
                  <StyledTableCell align="right">Type</StyledTableCell>
                  <StyledTableCell align="right">Status</StyledTableCell>
                  <StyledTableCell align="right">Updated At</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((station) => (
                    <StyledTableRow key={station.FuelStationID}>
                      <StyledTableCell>{station.FuelStationName}</StyledTableCell>
                      <StyledTableCell align="right">{station.Address}</StyledTableCell>
                      <StyledTableCell align="right">{station.City}</StyledTableCell>
                      <StyledTableCell align="right">{station.Region}</StyledTableCell>
                      <StyledTableCell align="right">{station.StationType}</StyledTableCell>
                      <StyledTableCell align="right">
                        <Select value={station.Status || "Pending"} onChange={(e) => handleStatusChange(station.FuelStationID, e.target.value)}>
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                          <MenuItem value="Rejected">Rejected</MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {station.UpdatedAt ? new Date(station.UpdatedAt * 1000).toLocaleString() : "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {totalPages > 1 && (
            <div className="d-flex justify-content-center mt-3">
              <Pagination count={totalPages} page={page} onChange={handlePageChange} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FuelStationDetails;
