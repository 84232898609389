import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Typography, Box, CircularProgress,
  MenuItem, Chip, IconButton, Card, CardContent
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { UserContext } from '../contexts/UserContext';
import { createTenant, getAllTenants } from '../services/tenantsService';
import { utcTimestampToNZTimezoneDateFormat } from '../utils/format';

export const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTenant, setNewTenant] = useState({
    tenantName: '',
    status: 'Active',
  });
  const [formError, setFormError] = useState('');
  const { token } = useContext(UserContext);


  const fetchTenants = async () => {
    try {
      setLoading(true);
      const response = await getAllTenants(token);
      setTenants(response.data || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching tenants:', err);
      setError('Failed to load tenants. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, [token]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setNewTenant({ tenantName: '', status: 'Active' });
    setFormError('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTenant(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!newTenant.tenantName.trim()) {
      setFormError('Tenant name is required');
      return;
    }

    try {
      setLoading(true);
      await createTenant(newTenant.tenantName, newTenant.status, token);
      handleCloseDialog();
      await fetchTenants();
    } catch (err) {
      console.error('Error creating tenant:', err);
      setFormError('Failed to create tenant. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading && tenants.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
            Tenant Management
          </Typography>
          <Box>
            <IconButton
              onClick={fetchTenants}
              sx={{ mr: 1 }}
              color="primary"
              disabled={loading}
              title="Refresh tenants"
            >
              <RefreshIcon />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenDialog}
              sx={{
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)'
                }
              }}
            >
              Add New Tenant
            </Button>
          </Box>
        </Box>

        {error && (
          <Box sx={{
            p: 2,
            mb: 3,
            backgroundColor: '#ffebee',
            borderRadius: '8px',
            border: '1px solid #ffcdd2'
          }}>
            <Typography color="error">
              {error}
            </Typography>
          </Box>
        )}

        <TableContainer
          component={Paper}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Tenant Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenants.length > 0 ? (
                tenants.map((tenant) => (
                  <TableRow
                    key={tenant.id}
                    hover
                    sx={{ '&:hover': { backgroundColor: '#f9f9f9' } }}
                  >
                    <TableCell>{tenant.TenantName}</TableCell>
                    <TableCell>
                      <Chip
                        label={tenant.Status}
                        color={tenant.Status === 'Active' ? 'success' : 'default'}
                        size="small"
                        sx={{
                          fontWeight: 'medium',
                          minWidth: '80px'
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {utcTimestampToNZTimezoneDateFormat(tenant.CreatedAt)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center" sx={{ py: 4 }}>
                    {loading ? (
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <CircularProgress size={40} sx={{ mb: 2 }} />
                        <Typography color="textSecondary">Loading tenants...</Typography>
                      </Box>
                    ) : (
                      <Typography color="textSecondary">
                        No tenants available
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add Tenant Dialog */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: '12px',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
            }
          }}
        >
          <DialogTitle sx={{
            backgroundColor: '#1976d2',
            color: 'white',
            fontWeight: 'bold'
          }}>
            Add New Tenant
          </DialogTitle>
          <DialogContent sx={{ pt: 3 }}>
            <Box>
              <TextField
                autoFocus
                margin="dense"
                id="tenantName"
                name="tenantName"
                label="Tenant Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.tenantName}
                onChange={handleInputChange}
                error={!!formError}
                helperText={formError}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                select
                fullWidth
                label="Status"
                name="status"
                value={newTenant.status}
                onChange={handleInputChange}
                variant="outlined"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              onClick={handleCloseDialog}
              sx={{
                borderRadius: '8px',
                color: '#666',
                fontWeight: 'medium'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                borderRadius: '8px',
                minWidth: '100px',
                fontWeight: 'medium'
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Create Tenant'}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};