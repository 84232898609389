import axios from 'axios';

export const getAllParksAndDetails = async (token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_PARKING_API}/parks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching parking details:', error);
    throw error;
  }
};

export const updateParkingLotStatus = async (parkId, status, token) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_PARKING_API}/parks/${parkId}/status`, 
      { status }, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating parking lot status:', error);
    throw error;
  }
};

export const deleteParkingLot = async (parkId, token) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_PARKING_API}/parkdetails/${parkId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting parking lot:', error.response?.data || error.message);
    throw error;
  }
};
