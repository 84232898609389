import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { Spinner } from '../utils/Spinner';
import { getAllParksAndDetails, updateParkingLotStatus, deleteParkingLot } from '../services/parkingServices';
import { showDefaultAlert, showConfirmationAlert } from '../utils/alerts';
import { UserContext } from '../contexts/UserContext';

const ParkingDetails = () => {
  const { token } = useContext(UserContext);

  // Styled Table Cells
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  // State Management
  const [parkingData, setParkingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10; // Fixed pagination size

  // Fetch Parking Data
  const fetchParkingDetails = async () => {
    setLoading(true);
    try {
      const response = await getAllParksAndDetails(token);
      const sortedData = response.result.sort((a, b) => b.updatedAt - a.updatedAt);
      setParkingData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching parking details:', error);
      showDefaultAlert('error', 'Error', 'Failed to fetch parking lot details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParkingDetails();
  }, []);

  // Handle Status Change
  const handleStatusChange = async (parkId, newStatus) => {
    try {
      await updateParkingLotStatus(parkId, newStatus, token);
      setParkingData((prevData) =>
        prevData.map((lot) => (lot.parkId === parkId ? { ...lot, status: newStatus } : lot))
      );
      setFilteredData((prevData) =>
        prevData.map((lot) => (lot.parkId === parkId ? { ...lot, status: newStatus } : lot))
      );
      showDefaultAlert('success', 'Success', 'Parking lot status updated successfully.');
    } catch (error) {
      console.error('Error updating status:', error);
      showDefaultAlert('error', 'Error', 'Failed to update status.');
    }
  };

  // Handle Delete
  const handleDelete = async (parkId) => {
    showConfirmationAlert({
      title: 'Delete Parking Lot',
      text: 'Are you sure you want to delete this parking lot?',
      confirmButtonText: 'Yes, Delete',
      onConfirm: async () => {
        setLoading(true);
        try {
          await deleteParkingLot(parkId, token);
          setParkingData((prevData) => prevData.filter((lot) => lot.parkId !== parkId));
          setFilteredData((prevData) => prevData.filter((lot) => lot.parkId !== parkId));
          showDefaultAlert('success', 'Success', 'Parking lot deleted successfully.');
        } catch (error) {
          console.error('Error deleting parking lot:', error);
          showDefaultAlert('error', 'Error', 'Failed to delete parking lot.');
        } finally {
          setLoading(false);
        }
      },
    });
  };

  // Handle Filtering
  const handleFilterChange = (status) => {
    setFilterStatus(status);
    setPage(1);
    if (status === 'All') {
      setFilteredData(parkingData);
    } else {
      setFilteredData(parkingData.filter((lot) => lot.status === status));
    }
  };

  // Handle Search
  useEffect(() => {
    let results = parkingData;
    if (filterStatus !== 'All') {
      results = results.filter((lot) => lot.status === filterStatus);
    }
    if (searchQuery) {
      results = results.filter(
        (lot) =>
          lot.parkName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          lot.locationId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredData(results);
    setPage(1);
  }, [searchQuery, filterStatus, parkingData]);

  // Handle Sorting
  // const sortDataByUpdatedAt = () => {
  //   const sortedData = [...filteredData].sort((a, b) => {
  //     return sortOrder === 'asc' ? a.updatedAt - b.updatedAt : b.updatedAt - a.updatedAt;
  //   });
  //   setFilteredData(sortedData);
  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  // };

  // Calculate Pagination
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <div className="container py-5">
      <header className="text-center">
        <h1>Parking Lots</h1>
      </header>

      {/* Search & Filter */}
      <div className="mb-3 d-flex justify-content-between">
        <TextField
          label="Search by Name or Location ID"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Select value={filterStatus} onChange={(e) => handleFilterChange(e.target.value)} style={{ minWidth: 200, marginLeft: 10 }}>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
          <MenuItem value="Rejected">Rejected</MenuItem>
        </Select>
        {/* <Button variant="contained" onClick={sortDataByUpdatedAt} style={{ marginLeft: 10 }}>
          Sort by UpdatedAt ({sortOrder === 'asc' ? 'Oldest First' : 'Newest First'})
        </Button> */}
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Park Name</StyledTableCell>
                  <StyledTableCell align="right">Number of Spots</StyledTableCell>
                  <StyledTableCell align="right">Location ID</StyledTableCell>
                  <StyledTableCell align="right">Status</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((lot) => (
                  <StyledTableRow key={lot.parkId}>
                    <StyledTableCell>{lot.parkName}</StyledTableCell>
                    <StyledTableCell align="right">{lot.numberOfSpots}</StyledTableCell>
                    <StyledTableCell align="right">{lot.locationId}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Select
                        value={lot.status || "Pending"}
                        onChange={(e) => handleStatusChange(lot.parkId, e.target.value)}
                      >
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                      </Select>
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <Button variant="outlined" color="secondary" onClick={() => handleDelete(lot.parkId)}>Delete</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {totalPages > 1 && <Pagination count={totalPages} page={page} onChange={(e, value) => setPage(value)} />}
        </>
      )}
    </div>
  );
};

export default ParkingDetails;
